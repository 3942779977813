import React, { useState } from "react";
import Amplify, { Auth } from "aws-amplify";
import "../styles/ForgetPassword.css";
import { Link } from "react-router-dom";

export default function ForgotPassword(props) {
  const [stage, setStage] = useState(0);
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleForgotPassword = async () => {
    try {
      await Auth.forgotPassword(email);
      setMessage("Verification code sent to your email.");
      setStage(1);
    } catch (error) {
      setMessage(error.message);
    }
  };

  const handleVerifyCodeAndResetPassword = async () => {
    try {
      await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
      setMessage("");
      setStage(2);
    } catch (error) {
      setMessage(error.message);
    }
  };

  const renderContent = () => {
    switch (stage) {
      case 0:
        return (
          <div className="input-container">
            <h1>Forgot Password</h1>
            <p>Please enter your email to receive a password reset link.</p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              autoComplete="new-password"
            />
            <button className="action-btn" onClick={handleForgotPassword}>
              Send Verification Code
            </button>
          </div>
        );
      case 1:
        return (
          <div className="input-container">
            <h1>Forgot Password</h1>
            <p>Please enter the verification code sent to your email.</p>
            <div className="input-field-container">
              <label className="input-label">Verification Code:</label>
              <input
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="Enter verification code"
                autoComplete="new-password"
              />
            </div>
            <div className="input-field-container">
              <label className="input-label">New Password:</label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter your new password"
                autoComplete="new-password"
              />
            </div>
            <button
              className="action-btn"
              onClick={handleVerifyCodeAndResetPassword}
            >
              Reset Password
            </button>
          </div>
        );
      case 2:
        return (
          <div className="input-container">
            <h1>Password Reset Completed</h1>
            {/* Add a link or button to go back to the login page */}
            <p>
              Password reset successful. You can now log in{" "}
              <Link to="/signin">here</Link> with your new password.
            </p>
            <Link to="/signin">
              <button>Login</button>
            </Link>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="forget-password-container">
      {renderContent()}
      {message && <p className="message">{message}</p>}
    </div>
  );
}
