import React from "react";

import "../../styles/FollowUpBossInstruction.css";

import step_1 from "../../assets/images/followUpBoss/Step_1.png";
import step_2 from "../../assets/images/followUpBoss/Step_2.png";
import step_3 from "../../assets/images/followUpBoss/Step_3.png";
import step_4 from "../../assets/images/followUpBoss/Step_4.png";
import step_5 from "../../assets/images/followUpBoss/Step_5.png";
import step_6 from "../../assets/images/followUpBoss/Step_6.png";

const FollowUpBossIntegration = () => {
  return (
    <div className="followUpBossSection">
      <h3>
        1. Go to your Follow Up Boss Dashboard and click to "Admin" link. Then
        select "More" options from top sub menu and Click to "API" link.
      </h3>
      <img src={step_1} />
      <h3>2. Click "Create API Key" button to generate new integraton key.</h3>
      <img src={step_2} />
      <h3>
        3. Then enter a name for API Key and click to "Create API Key" button.
      </h3>
      <img src={step_3} />
      <h3>4. Copy your new API Key and save it for future usage.</h3>
      <img src={step_4} />
      <h3>
        5. When you have your API Key from Follow Up Boss then go to Honely
        Realtors Dashboard and Under "Account Management" menu select
        "Integration". And Add your new Follow Up Boss Integration.
      </h3>
      <img src={step_5} />
      <h3>
        6. When your integration is successfully completed you will see new
        integration under the "Your integration" section.
      </h3>
      <img src={step_6} />
      <hr />
    </div>
  );
};

export default FollowUpBossIntegration;
