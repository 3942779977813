import React from 'react'
const LogoIcon = ({ color }) => {
  return (
    <svg width="32" height="37" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.21318 0.680711C9.96333 0.956774 11.7534 2.33986 11.9665 4.47162C9.35424 1.99541 6.97644 5.01127 4.84096 5.06089C2.36475 5.11844 0.346177 3.85578 0 0C1.43479 1.74493 2.79727 2.25928 4.84065 1.23599C5.8817 0.714662 6.67191 0.437597 8.21318 0.680711Z" fill={`${color}`}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.0438 6.21484C13.1876 6.21484 14.1149 7.14212 14.1149 8.28597V34.06C14.1149 35.2039 13.1876 36.1312 12.0438 36.1312C10.8999 36.1312 9.97266 35.2039 9.97266 34.06V8.28597C9.97266 7.14212 10.8999 6.21484 12.0438 6.21484ZM26.0651 16.2874C27.209 16.2874 28.1363 17.2147 28.1363 18.3586V34.06C28.1363 35.2039 27.209 36.1312 26.0651 36.1312C24.9213 36.1312 23.994 35.2039 23.994 34.06V18.3586C23.994 17.2147 24.9213 16.2874 26.0651 16.2874Z" fill={`${color}`}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.82465 21.0687L7.82537 19.2546C6.82609 17.4405 6.82618 17.4405 6.82631 17.4404L6.94103 17.3774C7.01504 17.3368 7.12327 17.2776 7.26122 17.2023C7.53709 17.0519 7.93206 16.8374 8.41012 16.5801C9.36535 16.0659 10.6565 15.3789 11.9942 14.6905C13.3269 14.0046 14.7276 13.3061 15.8954 12.775C16.4765 12.5106 17.0288 12.2745 17.5006 12.1004C17.7357 12.0136 17.9776 11.9322 18.2095 11.87C18.4073 11.817 18.738 11.7383 19.1015 11.7383C19.4767 11.7383 19.81 11.8216 20.0144 11.8805C20.2476 11.9477 20.4836 12.0349 20.7059 12.1256C21.1515 12.3072 21.6582 12.5508 22.1786 12.8184C23.2253 13.3568 24.4599 14.0616 25.6227 14.7495C26.7911 15.4408 27.9126 16.1303 28.7403 16.6458C29.1547 16.9039 29.4966 17.1191 29.7354 17.2702C29.8549 17.3457 29.9486 17.4052 30.0128 17.4461L30.1124 17.5096C30.1125 17.5096 30.113 17.51 28.9969 19.2546L30.1124 17.5096C31.0759 18.126 31.358 19.4072 30.7416 20.3707C30.1252 21.3343 28.8444 21.6157 27.8808 20.9993L27.7892 20.9409C27.728 20.902 27.6375 20.8445 27.5215 20.7711C27.2894 20.6244 26.9556 20.4142 26.5503 20.1618C25.7388 19.6564 24.6459 18.9845 23.5134 18.3145C22.3753 17.6412 21.2225 16.9847 20.2841 16.502C19.812 16.2592 19.4267 16.0773 19.1419 15.9612C19.1191 15.9519 19.0976 15.9433 19.0775 15.9354C19.0352 15.9499 18.9878 15.9669 18.9349 15.9864C18.5998 16.11 18.1516 16.2993 17.6104 16.5455C16.5335 17.0353 15.2021 17.6981 13.8897 18.3736C12.5822 19.0465 11.315 19.7207 10.3733 20.2276C9.90284 20.4808 9.51465 20.6916 9.24447 20.839C9.10941 20.9126 9.00389 20.9704 8.93242 21.0096L8.82465 21.0687ZM6.01125 20.2539C5.45937 19.252 5.8244 17.9923 6.82631 17.4404L7.82537 19.2546L8.82465 21.0687C7.82274 21.6206 6.56314 21.2558 6.01125 20.2539Z" fill={`${color}`}/>
    </svg>
  )
}

const CloseIcon = ({ color }) => {
  return (
    <svg width="16px" height="16px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g id="icons" stroke="none" fill="none">
        <g id="icon/close" fill={color ? color : 'black'}>
          <path d="M6.707 5.293L12 10.585l5.293-5.292a1 1 0 011.414 1.414L13.415 12l5.292 5.293a1 1 0 01-1.414 1.414L12 13.415l-5.293 5.292a1 1 0 11-1.414-1.414L10.585 12 5.293 6.707a1 1 0 011.414-1.414z" id="icon">
          </path>
        </g>
      </g>
    </svg>
  )
}

const LocationIcon = () => {
  return (
    <svg width="14" height="18" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.789062 9.17887C0.789062 4.19568 4.95327 0.167969 9.9903 0.167969C15.0415 0.167969 19.2057 4.19568 19.2057 9.17887C19.2057 11.69 18.2925 14.0212 16.7894 15.9972C15.1311 18.1768 13.0872 20.0758 10.7867 21.5664C10.2601 21.9109 9.78493 21.9369 9.20705 21.5664C6.89336 20.0758 4.84949 18.1768 3.20544 15.9972C1.70121 14.0212 0.789062 11.69 0.789062 9.17887ZM6.95793 9.45947C6.95793 11.1288 8.32014 12.4418 9.99041 12.4418C11.6618 12.4418 13.0371 11.1288 13.0371 9.45947C13.0371 7.80311 11.6618 6.42623 9.99041 6.42623C8.32014 6.42623 6.95793 7.80311 6.95793 9.45947Z" fill="#CFCED3"/>
    </svg>
  )
}

const MessageIcon = () => {
  return (
    <svg width="14" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.939 0C16.28 0 17.57 0.53 18.519 1.481C19.469 2.43 20 3.71 20 5.05V12.95C20 15.74 17.73 18 14.939 18H5.06C2.269 18 0 15.74 0 12.95V5.05C0 2.26 2.259 0 5.06 0H14.939ZM16.53 6.54L16.61 6.46C16.849 6.17 16.849 5.75 16.599 5.46C16.46 5.311 16.269 5.22 16.07 5.2C15.86 5.189 15.66 5.26 15.509 5.4L11 9C10.42 9.481 9.589 9.481 9 9L4.5 5.4C4.189 5.17 3.759 5.2 3.5 5.47C3.23 5.74 3.2 6.17 3.429 6.47L3.56 6.6L8.11 10.15C8.67 10.59 9.349 10.83 10.06 10.83C10.769 10.83 11.46 10.59 12.019 10.15L16.53 6.54Z" fill="#CFCED3"/>
    </svg>
  )
}

const UserIcon = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.294 5.29105C13.294 8.22808 10.9391 10.5831 8 10.5831C5.0619 10.5831 2.70601 8.22808 2.70601 5.29105C2.70601 2.35402 5.0619 0 8 0C10.9391 0 13.294 2.35402 13.294 5.29105ZM8 20C3.66237 20 0 19.295 0 16.575C0 13.8539 3.68538 13.1739 8 13.1739C12.3386 13.1739 16 13.8789 16 16.599C16 19.32 12.3146 20 8 20Z" fill="#CFCED3" />
    </svg>
  )
}

const PhoneIcon = () => {
  return (
    <svg width="14" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.53174 10.4724C13.5208 14.4604 14.4258 9.84672 16.9656 12.3848C19.4143 14.8328 20.8216 15.3232 17.7192 18.4247C17.3306 18.737 14.8616 22.4943 6.1846 13.8197C-2.49348 5.144 1.26158 2.67244 1.57397 2.28395C4.68387 -0.826154 5.16586 0.589383 7.61449 3.03733C10.1544 5.5765 5.54266 6.48441 9.53174 10.4724Z" fill="#CFCED3" />
    </svg>
  )
}

const ArrowDownIcon = ({ color }) => {
  return (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L6.14286 6L11 1" stroke={color ? color : 'black'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

const ArrowUpIcon = ({ color }) => {
  return (
    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 5L6 1L0.999999 5" stroke={`${color}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

const ContactIcon = ({ color }) => {
  return (
    <svg width="24" height="16" viewBox="0 0 34 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1V21H33V1M1 1H33M1 1L17.5926 14L33 1" stroke={`${color}`} strokeLinejoin="round"/>
    </svg>
  )
}

const InfoIcon = () => {
  return (
    <svg viewBox="0 0 16 16" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M8 16A8 8 0 108 0a8 8 0 000 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" fill="#CFCED3"></path>
    </svg>
  )
}

export {
  LogoIcon,
  CloseIcon,
  LocationIcon,
  MessageIcon,
  UserIcon,
  PhoneIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  ContactIcon,
  InfoIcon
}
