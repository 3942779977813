import React, { useEffect, useState } from "react";
import Select from "react-select";
import toast from "react-hot-toast";
import MdAddCircle from "@meronex/icons/ios/MdAddCircle";
import MdDelete from "@meronex/icons/md/MdDelete";
// import AiFillEdit from "@meronex/icons/ai/AiFillEdit";
import MdcHelpCircleOutline from "@meronex/icons/mdc/MdcHelpCircleOutline";
import axios from "axios";

import "../../styles/Integration.css";
import MdAddCircleOutline from "@meronex/icons/ios/MdAddCircleOutline";
import FollowUpBossIntegration from "./FollowUpBossIntegration";

const IntegrationPage = ({ jwt }) => {
  const [addIntegration, setAddIntegration] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState({});
  const [apiKey, setApiKey] = useState("");
  const [userIntegration, setUserIntegration] = useState([]);
  const [showInstruction, setShowInstraction] = useState(false);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: "Bearer " + jwt,
      },
    };
    axios
      .get("https://developers.honely.com/integrations/external", config)
      .then((response) => {
        setUserIntegration(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const integrationOptions = [{ value: "fup", label: "Follow Up Boss" }];

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: isFocused ? "#fff" : "#333333",
      };
    },
  };

  const newIntegrationHandler = () => {
    setAddIntegration(() => !addIntegration);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedIntegration(selectedOption);
  };

  const onSubmitHandler = () => {
    // Check if all fields filled out
    if (Object.keys(selectedIntegration).length === 0 || !apiKey) {
      return toast.error("Please fill out all fields.");
    }
    const config = {
      headers: {
        Authorization: "Bearer " + jwt,
      },
    };

    const data = {
      "integration-name": selectedIntegration.label,
      "integration-short-name": selectedIntegration.value,
      "integration-apikey": apiKey,
    };

    // Make api call and save data to DB
    axios
      .post("https://developers.honely.com/integrations/external", data, config)
      .then((response) => {
        setUserIntegration([response.data.data, ...userIntegration]);
        toast.success("Integration successfully added.");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Cannot create integration at this time. Please try later");
      });

    setAddIntegration(false);
  };

  const onDeleteIntegrationHandler = (apiKey) => {
    const config = {
      headers: {
        Authorization: "Bearer " + jwt,
      },
    };
    axios
      .delete(
        "https://developers.honely.com/integrations/external/" + apiKey,
        config
      )
      .then((response) => {
        setUserIntegration(
          userIntegration.filter(
            (integration) => integration.integration_api_key !== apiKey
          )
        );

        toast.success("Integration successfully removed from the account");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Cannot delete integration. Please contact us.");
      });
  };

  const onHelpClickHandler = () => {
    setShowInstraction(!showInstruction);
  };

  return (
    <div className="container">
      <div className="newIntegrationSection">
        {/* Button block */}
        <div className="addNewIntegration" onClick={newIntegrationHandler}>
          <MdAddCircleOutline className="icon" /> <p>New Integration</p>
        </div>
        {/* Inputs block */}
        {addIntegration && (
          <>
            <div className="newIntegrationRow">
              <Select
                className="react-select-container"
                options={integrationOptions}
                styles={colourStyles}
                onChange={handleSelectChange}
              />
              <div className="inputRow">
                <input
                  type="text"
                  placeholder="Enter your API key"
                  onChange={(e) => setApiKey(e.target.value)}
                />
                <div className="doneIcon" onClick={onSubmitHandler}>
                  <MdAddCircle />
                </div>
                {Object.keys(selectedIntegration).length !== 0 && (
                  <div className="doneIcon" onClick={onHelpClickHandler}>
                    <MdcHelpCircleOutline />
                  </div>
                )}
              </div>
            </div>
            {showInstruction && (
              <div className="instructionContainer">
                <h2>Instruction for {selectedIntegration.label} integration</h2>

                <div className="instructionContainer content">
                  {selectedIntegration.value === "fup" && (
                    <FollowUpBossIntegration />
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="integrationSection">
        {!userIntegration.length && <p>No integrations yet</p>}

        {userIntegration.length >= 1 &&
          userIntegration.map((integration) => {
            return (
              <div
                className="integrationSection row"
                key={integration.integration_api_key}
              >
                <p>{integration.integration_name}</p>
                <p className="integrationSection row key">
                  {integration.integration_api_key}{" "}
                  <MdDelete
                    className="icon"
                    onClick={() =>
                      onDeleteIntegrationHandler(
                        integration.integration_api_key
                      )
                    }
                  />{" "}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default IntegrationPage;
