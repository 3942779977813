export const parsePrice = (num, isFixed = false) => {
  const number = Math.round(parseFloat(num) / 100) * 100;
  const fixedNumber = isFixed
    ? Number.parseFloat(number).toFixed(2)
    : Number.parseFloat(number);
  return "$" + String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const changeDashes = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      changeDashes(obj[key]);
    } else if (obj[key] === "--") {
      console.log(key, obj[key]);
      obj[key] = "N/A";
    }
  });
};
