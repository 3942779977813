/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    // "aws_project_region": "us-east-1"
    "aws_project_region": "us-east-1",
    // "aws_cognito_identity_pool_id": "us-east-1:d914c4bd-5879-4945-a64a-1c5ab5f75e00",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ipUXS9Ma8",
    "aws_user_pools_web_client_id": "6ialng39qervtedvnhmo1459sg",
    "oauth": {
        "domain": "honelyuserpool.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        // "redirectSignIn": "http://localhost:8080/",
        // "redirectSignOut": "http://localhost:8080/",
        // "redirectSignIn": "https://d3vysvze1cydzh.cloudfront.net/",
        // "redirectSignOut": "https://d3vysvze1cydzh.cloudfront.net/",
        "redirectSignIn": "https://www.honely.com/",
        "redirectSignOut": "https://www.honely.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
};


export default awsmobile;
